import { ChangeDetectorRef, Component, ElementRef, inject, Injector, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { IS_SERVER } from '@core/config/tokens';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
  protected destroyed$: Subject<boolean> = new Subject();
  protected i18RootKey: string = null;

  protected readonly injector = inject(Injector);
  protected readonly isServer = inject(IS_SERVER);
  protected readonly el = inject(ElementRef);
  protected readonly cd = inject(ChangeDetectorRef);

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public i18n(key: string, extras: string | string[] = null): string {
    const collection: string[] = [];

    if (extras) {
      extras = Array.isArray(extras) ? extras : [extras];
      collection.push(...extras);
    } else {
      collection.push(this.i18RootKey);
    }

    collection.push(key);

    return collection.join('.');
  }

  /* Wrapper for applying custom properties to component element
   *
   * Trying to apply CSS variables will throw an error when running SSR.
   * User this to prevent those errors.
   */
  protected setCustomProperty(key: string, value: string, el: HTMLElement = null): void {
    if (this.isServer) {
      return;
    }

    el ||= this.el.nativeElement;

    el.style.setProperty(key, value);
  }
}
